import React, { useEffect, useMemo, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import BrigadeLabelRequestModal from './BrigadeLabelRequestModal'

const BrigadeLabelRequestModalWrapper = ({
  actions = [],
  imageUrl,
  name,
  postUrl,
  shouldReloadOnProfilePage = false,
  subheader,
  text,
  type = 'success',
}) => {
  const [isLabelRequestModalOpen, setIsLabelRequestModalOpen] = useState(false)
  const csrfToken = useMemo(
    () => document.querySelector('meta[name="csrf-token"]')?.content,
    [],
  )
  useEffect(() => {
    if (text) {
      setIsLabelRequestModalOpen(true)
    }
  }, [text])

  const handleCloseModal = useCallback(async () => {
    setIsLabelRequestModalOpen(false)

    if (postUrl && csrfToken) {
      try {
        const response = await axios.post(
          postUrl,
          {},
          {
            headers: {
              'X-CSRF-Token': csrfToken,
            },
          },
        )

        if (
          response.status >= 200 &&
          response.status < 300 &&
          shouldReloadOnProfilePage
        ) {
          // Reload the page if on the profile page
          window.location.reload()
        }
      } catch (error) {
        console.error('Error sending Session clear POST request:', error)
      }
    }
  }, [postUrl, csrfToken, shouldReloadOnProfilePage])

  return (
    <BrigadeLabelRequestModal
      actions={actions}
      imageUrl={imageUrl}
      isOpen={isLabelRequestModalOpen}
      name={name}
      onClose={handleCloseModal}
      subheader={subheader}
      text={text}
      type={type}
    />
  )
}

BrigadeLabelRequestModalWrapper.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      buttonClass: PropTypes.string,
      url: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  imageUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  postUrl: PropTypes.string.isRequired,
  shouldReloadOnProfilePage: PropTypes.bool,
  subheader: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.oneOf(['success', 'error']),
}

export default BrigadeLabelRequestModalWrapper
