import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { MainModal , SanitizeHTML } from '@tc-packages/tc-common'

const BrigadeLabelRequestModal = ({
  actions = [],
  imageUrl,
  isOpen = false,
  name,
  onClose = () => {},
  renderActions,
  subheader,
  text,
  type = 'success',
}) => {
  const textClass = `brigade-modal__text ${type === 'error' ? 'has-error' : ''}`

  const memoizedActions = useMemo(() => actions, [actions])

  const handleActionClick = async (action) => {
    if (action.url) {
      try {
        await onClose()
        window.location.href = action.url
      } catch (error) {
        console.error('Error during action click:', error)
      }
    } else if (action.onClick) {
      action.onClick()
    }
  }

  const modalBody = (
    <div className="py-5">
      <div className="brigade-modal__header">
        <img
          alt={`Image for ${name}`}
          className="brigade-modal__image"
          height="130"
          src={imageUrl}
          width="auto"
        />
        <h2 className="brigade-modal__title" id="custom-modal-title">
          {name}
        </h2>
      </div>
      <div
        aria-labelledby="custom-modal-title"
        className={textClass}
        id="custom-modal-description"
        role="document"
      >
        <h3>{subheader}</h3>
        <SanitizeHTML html={text} />
      </div>
      {renderActions
        ? renderActions(memoizedActions)
        : memoizedActions.length > 0 && (
            <div className="brigade-modal__actions">
              {memoizedActions.map((action) =>
                action.url ? (
                  <a
                    className={
                      action.buttonClass ? `${action.buttonClass}` : ''
                    }
                    href={action.url}
                    key={action.id}
                    onClick={(e) => {
                      e.preventDefault()
                      handleActionClick(action)
                    }}
                  >
                    {action.text}
                  </a>
                ) : (
                  <button
                    className={
                      action.buttonClass ? `${action.buttonClass}` : ''
                    }
                    key={action.id}
                    onClick={action.onClick}
                    type="button"
                  >
                    {action.text}
                  </button>
                ),
              )}
            </div>
          )}
    </div>
  )

  return (
    <MainModal
      additionalClasses="small-modal"
      handleClose={onClose}
      isModalOpen={isOpen}
      modalBody={modalBody}
    />
  )
}

BrigadeLabelRequestModal.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      url: PropTypes.string,
      text: PropTypes.string.isRequired,
      buttonClass: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  imageUrl: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  renderActions: PropTypes.func,
  subheader: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['success', 'error']),
}

export default BrigadeLabelRequestModal
