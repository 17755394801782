import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal } from '@mui/material'
import UserAvailablePoints from './UserAvailablePoints'
import FlashMessage from './FlashMessage'

const RedeemCharity = ({ charity, parentsProps, updateSaveOrg }) => {
  const [openModal, setOpenModal] = useState(false)
  const [amount, setAmount] = useState('')
  const [hasError, setHasError] = useState(null)
  const [isSuccess, setIsSuccess] = useState(false)
  const [disabled, setDisabled] = useState(false)

  const handleOpenModal = (e) => {
    e.preventDefault()
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    setHasError(null)
    setAmount('')
  }

  const submitRedeem = async (e) => {
    e.preventDefault()
    if (disabled) return

    setDisabled(true)
    try {
      const response = await fetch(parentsProps.redeemCharityPath, {
        body: JSON.stringify({
          amount,
          charity_id: charity.id
        }),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      })

      const data = await response.json()
      if (response.ok) {
        handleCloseModal()
        updateSaveOrg()
        setIsSuccess(true)
        document.getElementById('userPoints').dispatchEvent(new Event('change'))
      } else {
        throw new Error(data && data.errors ? data.errors : 'Failed to redeem')
      }
    } catch (error) {
      setHasError(error.message)
      setDisabled(false)
    }
  }

  return (
    <React.Fragment>
      {parentsProps.currentUser ? (
        <a className="me-5 order-1" href="#" onClick={handleOpenModal}>
          <b>{parentsProps.t['tcnext.redeem']}</b>
        </a>
      ) : (
        <a className="me-5" href={parentsProps.newUserSessionPath}>
          <b>{parentsProps.t['tcnext.redeem']}</b>
        </a>
      )}
      <Modal
        aria-labelledby="redeem-charity-modal"
        className="modal-material"
        onClose={handleCloseModal}
        open={openModal}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <button className="closeModalButton" onClick={handleCloseModal}>
                <span>+</span>
              </button>
              <div className="pointModal_headline">
                <div className="pointModal_title">
                  {parentsProps.t['tcred.current_points_balance']}
                </div>
                <div className="pointModal_points">
                  <UserAvailablePoints
                    availablePointsPath={parentsProps.availablePointsPath}
                    failedToFetchPoints={parentsProps.t['redeem.failed_to_fetch_available_points']}
                  />
                </div>
              </div>
              <div className="pointModal_form">
                <form onSubmit={submitRedeem}>
                  <fieldset className="bootstrapForm_fieldset">
                    <label className="mb-2" htmlFor="redeemCharity">{charity.name}</label>
                    <div className="bootstrapForm_input">
                      <input id="redeemCharity"
                        onChange={(e) => setAmount(e.target.value)}
                        onKeyPress={() => setHasError(null)}
                        placeholder={parentsProps.t['tcred.minimum_points']}
                        type="number"
                        value={amount}
                      />
                    </div>
                    {hasError && <span className="pointModal_error">{hasError}</span>}
                  </fieldset>
                  <div className="bootstrapForm_actions">
                    <div className="col-md-8 mx-auto">
                      <input className="buttonComponent--primary w-100" disabled={disabled} type="submit" value={parentsProps.t['tcred.redeem_points']} />
                      <button className="btn pointModal_cancel-btn m-0 mt-2 mt-lg-4" onClick={handleCloseModal}>
                        {parentsProps.t['tcred.cancel_this_gift']}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <FlashMessage handleClose={() => setIsSuccess(false)}
        isSuccess={isSuccess}
        message={parentsProps.t['tcnext.cart.checkout_success']}
        status={'isSuccess'}
      />
    </React.Fragment>
  )
}

RedeemCharity.propTypes = {
  charity: PropTypes.object,
  parentsProps: PropTypes.object,
  updateSaveOrg: PropTypes.func
}

export default RedeemCharity
