import PropTypes from 'prop-types'
import React from 'react'
import { CircularProgress, Modal } from '@mui/material'
import FlashMessage from './FlashMessage'

export default class Enable2fa extends React.Component {
  constructor(props) {
    super(props)
    this.enable2faInputRef = React.createRef()
    this.state = {
      openModal: false,
      otpAttempt: '',
      hasError: null,
      isSuccess: false,
      disabled: false
    }
  }

  handleOpenModal = (e) => {
    e.preventDefault()

    if (!this.props.isRegenerate ||
      (this.props.isRegenerate && window.confirm(this.props.t['2fa.confirm_regeneration']))) {
      this.setState({ openModal: true })

      $.ajax({
        url: this.props.generate2faQRCodePath,
        type: 'POST'
      }).done((data) => {
        document.getElementById('2fa_qrcode').innerHTML = data.qr_code
        document.getElementById('2fa_backup_code').innerHTML = data.backup_code
        $('.enable2faModal_title, .enable2faModal_form').removeClass('d-none')
        this.enable2faInputRef.current.focus()
      }).fail((error) => {
        this.setState({ hasError: error.responseJSON.errors })
      })
    }
  }

  handleCloseModal = () => {
    this.setState({
      openModal: false,
      hasError: null,
      otpAttempt: ''
    })
  }

  submit2fa = (e) =>  {
    if (this.state.disabled) {
      return
    }
    this.setState({ disabled: true })

    e.preventDefault()
    $.ajax({
      url: this.props.enable2faPath,
      type: 'POST',
      dataType: 'json',
      data: { otp_attempt: this.state.otpAttempt },
    }).done(() => {
      this.setState({ isSuccess: true })
      window.location.reload()
    }).fail((error) => {
      this.setState({ hasError: error.responseJSON.error })
      this.setState({ disabled: false })
    })
  }

  render() {
    const { t } = this.props

    return (
      <React.Fragment>
        <a className="buttonComponent--primary me-5 order-1" href="#" onClick={(e) => this.handleOpenModal(e)}>
          <b>{t['2fa.enable']}</b>
        </a>
        <Modal
          className="modal-material"
          onClose={() => this.handleCloseModal()}
          open={this.state.openModal}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <button className="closeModalButton" onClick={() => this.handleCloseModal()}>
                  <span>+</span>
                </button>
                <div className="enable2faModal_headline mb-5">
                  <div className="enable2faModal_title d-none mb-5">
                    {t['2fa.scan_qr_code']}
                  </div>
                  <div className="enable2faModal_qrcode text-center mb-5" id="2fa_qrcode">
                    <div className="loader"><CircularProgress size={40} /></div>
                  </div>
                  <div className="enable2faModal_title d-none mb-5">
                    {t['2fa.save_backup_code']}
                  </div>
                  <div className="enable2faModal_backup_code text-center" id="2fa_backup_code">
                  </div>
                </div>
                <div className="enable2faModal_form d-none">
                  <form onSubmit={(e) => this.submit2fa(e)}>
                    <fieldset className="bootstrapForm_fieldset">
                      <label className="mb-3" htmlFor="enable2fa">{t['2fa.enter_verification_token']}</label>
                      <div className="bootstrapForm_input">
                        <input
                          id="enable2fa"
                          onChange={(e) => this.setState({otpAttempt: e.target.value})}
                          onKeyPress={() => this.setState({ hasError: null })}
                          placeholder={t['2fa.verification_code']}
                          ref={this.enable2faInputRef}
                          type="text"
                          value={this.state.otpAttempt}
                        />
                      </div>
                      {this.state.hasError && <span className="pointModal_error">{this.state.hasError}</span>}
                    </fieldset>
                    <div className="bootstrapForm_actions mt-5">
                      <div className="col-md-8 mx-auto">
                        <input className="buttonComponent--primary w-100" disabled={this.state.disabled} type="submit" value={t['2fa.enable']} />
                        <button className="buttonComponent--secondary buttonComponent--medium btn enable2faModal_cancel-btn m-0 mt-2 mt-lg-4" onClick={() => this.handleCloseModal()}>
                          {t['2fa.cancel']}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <FlashMessage handleClose={() => this.setState({ isSuccess: false })}
                      isSuccess={this.state.isSuccess}
                      message={t['2fa.successfully_enabled']}
                      status={'isSuccess'}
        />
      </React.Fragment>
    )
  }
}

Enable2fa.propTypes = {
  enable2faPath: PropTypes.string,
  generate2faQRCodePath: PropTypes.string,
  isRegenerate: PropTypes.bool,
  t: PropTypes.object
}
