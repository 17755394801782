import React, { useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import CharitiesMySaved from './CharitiesMySaved'
import CharitiesSearch from './CharitiesSearch'

const CharitiesWrapper = ({
  availablePointsPath,
  charitiesPath,
  currentUser,
  newCharityPath,
  newUserSessionPath,
  redeemCharityPath,
  savedCharitiesPath,
  t,
  userCanCreateCharity,
}) => {
  const childRef = useRef(null)

  const updateSaveOrg = useCallback(() => {
    if (childRef.current && childRef.current.loadJSON) {
      childRef.current.loadJSON()
    }
  }, [])

  return (
    <>
      {currentUser && (
        <CharitiesMySaved
          availablePointsPath={availablePointsPath}
          currentUser={currentUser}
          newUserSessionPath={newUserSessionPath}
          redeemCharityPath={redeemCharityPath}
          ref={childRef}
          savedCharitiesPath={savedCharitiesPath}
          t={t}
        />
      )}
      <CharitiesSearch
        availablePointsPath={availablePointsPath}
        charitiesPath={charitiesPath}
        currentUser={currentUser}
        newCharityPath={newCharityPath}
        newUserSessionPath={newUserSessionPath}
        redeemCharityPath={redeemCharityPath}
        t={t}
        updateSaveOrg={updateSaveOrg}
        userCanCreateCharity={userCanCreateCharity}
      />
    </>
  )
}

CharitiesWrapper.propTypes = {
  availablePointsPath: PropTypes.string,
  charitiesPath: PropTypes.string,
  currentUser: PropTypes.object,
  newCharityPath: PropTypes.string,
  newUserSessionPath: PropTypes.string,
  redeemCharityPath: PropTypes.string,
  savedCharitiesPath: PropTypes.string,
  t: PropTypes.object,
  userCanCreateCharity: PropTypes.bool,
}

export default CharitiesWrapper
